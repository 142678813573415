<template>
    <div :class="{'isEn':lang == 'en'}">
        <loading v-if="loading" :loading="loading"></loading>
        <div v-else>
          <div class="swiper">
          <el-carousel height="732px" :autoplay="true" interval="5000">
            <el-carousel-item v-for="(imgUrl,index) in bannerImgs" :key="index">
              <img class="banner" v-lazy="imgUrl">
            </el-carousel-item>
          </el-carousel>
        </div>
        <el-row type="flex" justify="center">
          <div class="cardsBox serverPro">
            <el-col v-for="(c,index) in $t('serveProMsg')" @click.native="_addClass(index)" :key="index" :span="3">
              <el-card class="card" shadow="hover">
                <div class="text item">
                  <h3 class="serveTitle">{{c.title}}</h3>
                  <img v-lazy="c.cardImgUrl">
                </div>
              </el-card>
            </el-col>
          </div>
        </el-row>
        <el-row type="flex" justify="center" class="introduce">
          <el-col :xs="24" :sm="23" :md="21" :lg="19" :xl="17">
              <el-card class="card">
                
                  <div>
                    <transition name="el-fade-in-linear" @after-leave="handleSPTrans">
                      <div v-if="SPState" class="container">
                        <div class="item leftItem">
                          <img v-lazy="$t('serverMsg')[serveProIndex].first.imgUrl" alt="">
                          <p>{{$t('serverMsg')[serveProIndex].first.itemMsg}}</p>
                        </div>
                        <div class="item rightItem">
                            <div class="otherItem" v-if="s.title" v-for="(s,index) in $t('serverMsg')[serveProIndex].other" :key="index">
                            <img v-lazy="s.imgUrl" alt="">
                            <h3 class="text_ov_el">{{s.title}}</h3>
                            <p class="text_l4">{{s.itemMsg}}</p>
                            <a href="javascript:;" v-if="index == 0" @click="jumpPage('ServicesAvailable',serveProIndex)">了解更多</a>
                            </div>
                        </div>
                      </div>
                    </transition>
                  </div>
              </el-card>
          </el-col>
        </el-row>
        <area-title :cname="$t('topBar.n4')"></area-title>
        <el-row type="flex" justify="center" class="casesShow">
          <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="3">
            <a-steps progress-dot :current="current" direction="vertical" @change="onStepChange">
              <a-step v-for="(item,index) in $t('serveProMsg')" :key="index" :title="item.title"  />
            </a-steps>
          </el-col>
          <el-col :xs="14" :sm="14" :md="14" :lg="14" :xl="12">
            <div class="steps-content">
              <transition name="el-fade-in-linear" @after-leave="handleCaseTrans">
                <div v-if="caseState">
                  <case-container :caseMsg="$t('casesMsg')[steps[current].content - 1]" :pindex="current"></case-container>
                </div>
              </transition>
            </div>
          </el-col>
        </el-row>
        <area-title :cname="$t('topBar.n5')"></area-title>
        <el-row type="flex" justify="center" class="introduce newsCenter">
          <el-col :xs="20" :sm="24" :md="20" :lg="20" :xl="18">
            <el-card class="card" shadow="never">
              <div class="container">
                <div class="item leftItem"  @click="goToNews(newsMsg[0].id)">
                  <img v-lazy="this.newsMsg[0].thumbimg" alt="">
                  <h3>{{this.newsMsg[0].title}}</h3>
                  <p>{{this.newsMsg[0].intro}}</p>
                  <p class="newsTime">{{this.newsMsg[0].createdAt}}</p>
                </div>
                <div class="item rightItem">
                    <table>
                        <tr v-for="(n,index) in newsMsg" v-if="index>0 && index < 4" :key="index" @click="goToNews(n.id)">
                          <td>
                            <img v-lazy="n.thumbimg" alt="">
                          </td>
                          <td>
                              <h3 class="text_ov_el">{{n.title}}</h3>
                              <p class="text_l3">{{n.intro}}</p>
                              <p>{{n.createdAt}}</p>
                          </td>
                        </tr>
                    </table>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <area-title :cname="$t('topBar.n7')"></area-title>
        <el-row type="flex" justify="center" :gutter="10">
          <div>
            <el-col class="cardsBox" v-for="(img,index) in serverImgList" :key="index" :span="3.5">
              <div class="text item">
                <img v-lazy="img.imgUrl">
              </div>
            </el-col>
          </div>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :xs="12" :sm="16" :md="16" :lg="16" :xl="12" class="brandImage">
            <img v-lazy="'http://cdn.valuetech.com.cn/images/%E9%A6%96%E9%A1%B5/%E6%9C%8D%E5%8A%A1%E8%83%BD%E5%8A%9B-%E5%90%88%E4%BD%9C%E5%AE%A2%E6%88%B7.png'" alt="">
          </el-col>
        </el-row>
        </div>
    </div>
</template>
<script>
import AreaTitle from "components/AreaTitle";
import CaseContainer from "components/CaseContainer";
import Loading from "components/Loading";

import Bus from "@/utils/bus.js";

import { bannerApi, newsApi } from "@/request/api.js";
import { addClass } from "@/utils";

export default {
  name: "index",
  components: {
    AreaTitle,
    CaseContainer,
    Loading,
  },
  data() {
    return {
      loading: true,
      SPState: true,
      caseState: true,
      transState: 0,
      active: 0,
      current: 0,
      serveProIndex: 0,
      bannerImgs: ["http://cdn.valuetech.com.cn/images/banner/banner1.jpg"],
      steps: [
        {
          title: "First",
          content: "1",
        },
        {
          title: "Second",
          content: "2",
        },
        {
          title: "Last",
          content: "3",
        },
        {
          title: "Last",
          content: "4",
        },
        {
          title: "Last",
          content: "5",
        },
        {
          title: "Last",
          content: "6",
        },
        {
          title: "Last",
          content: "7",
        },
      ],
      serverImgList: [
        {
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E9%A6%96%E9%A1%B5/%E6%9C%8D%E5%8A%A1%E8%83%BD%E5%8A%9B-10%E5%B9%B4%E8%99%9A%E6%8B%9F%E7%8E%B0%E5%AE%9E%E7%BB%8F%E9%AA%8C.png",
        },
        {
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E9%A6%96%E9%A1%B5/%E6%9C%8D%E5%8A%A1%E8%83%BD%E5%8A%9B-20%E5%B9%B4%E6%95%B0%E5%AD%97%E5%B1%95%E7%A4%BA%E7%BB%8F%E9%AA%8C.png",
        },
        {
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E9%A6%96%E9%A1%B5/%E6%9C%8D%E5%8A%A1%E8%83%BD%E5%8A%9B-%E5%85%A8%E7%A8%8B%E9%A1%BE%E9%97%AE%E6%8C%87%E5%AF%BC(1).png",
        },
        {
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E9%A6%96%E9%A1%B5/%E6%9C%8D%E5%8A%A1%E8%83%BD%E5%8A%9B-30%E5%B9%B4%E9%A1%BE%E9%97%AE%E7%BB%8F%E9%AA%8C.png",
        },
        {
          imgUrl:
            "http://cdn.valuetech.com.cn/images/%E9%A6%96%E9%A1%B5/%E6%9C%8D%E5%8A%A1%E8%83%BD%E5%8A%9B-24%E5%B0%8F%E6%97%B6%E5%AE%A2%E6%9C%8D.png",
        },
      ],
      caseMsg: [
        {
          content1: {
            title: "某日系豪华汽车品牌OneID",
            imgUrl: require("assets/images/01-奔驰展厅.jpg"),
            msg:
              "品牌打造以移动出行服务为目的的全新商业模式，OneID帮助品牌方打破数据孤岛，链接全触点，统合全域用户，成为品牌建设一元化信息平台的基石。",
          },
          content2: {
            title: "某日系二手车平台",
            imgUrl: require("assets/images/13.png"),
            msg:
              "随着新车市场的饱和，中国车市场由增量变为存量时代，品牌为了提升客户服务，打造了二手车平台，帮助车主更好的进行车辆的换购。",
          },
          content3: {
            title: "某韩系社交建设",
            imgUrl: require("assets/images/13.png"),
            msg:
              "围绕用户全生命周期搭建的用车服务/车生活/ 社交于一体的智能服务平台，促进用户向粉丝转化，实现口碑和销售裂变，提升品牌价值。",
          },
          content4: {
            title: "",
            imgUrl: require("assets/images/01.jpg"),
            msg: "",
          },
        },
        {
          content1: {
            title: "奔驰数字展厅软硬件系统建设",
            imgUrl: require("assets/images/01-奔驰展厅.jpg"),
            msg:
              "随着时代的发展，新一代消费者对于线下门店的体验有着更高的要求，奔驰为了提升到店客户的体验进行了整店软硬件数字化、智能化升级。",
          },
          content2: {
            title: "某日系豪华汽车品牌数字展厅建设",
            imgUrl: require("assets/images/0f916ba626eb3533258954d098d1eed.jpg"),
            msg:
              "随着科技的不断进步，为品牌进行了数字展厅体验全面升级，店内车型不齐的情况下给客户接近真实的看车体验。",
          },
          content3: {
            title: "",
            imgUrl: require("assets/images/13.png"),
            msg: "",
          },
          content4: {
            title: "",
            imgUrl: require("assets/images/01.jpg"),
            msg: "",
          },
        },
        {
          content1: {
            title: "某日系豪华汽车品牌会员等级权益体系",
            imgUrl: require("assets/images/01-奔驰展厅.jpg"),
            msg:
              "为了增强品牌会员的用车服务，在OneID的支持下，为品牌全触点建设了统一的等级和对应的权益服务。",
          },
          content2: {
            title: "某日系豪华汽车品牌积分体系",
            imgUrl: require("assets/images/0f916ba626eb3533258954d098d1eed.jpg"),
            msg:
              "为了增强触点用户的活跃和价值，进行了积分体系的建设，包含“积分获取事件定义、积分管理、积分商城”等体系的建设。",
          },
          content3: {
            title: "",
            imgUrl: require("assets/images/13.png"),
            msg: "",
          },
          content4: {
            title: "",
            imgUrl: require("assets/images/01.jpg"),
            msg: "",
          },
        },
        {
          content1: {
            title: "某日系豪华汽车品牌数字钥匙系统",
            imgUrl: require("assets/images/01-奔驰展厅.jpg"),
            msg:
              "为了更好的提升客户用车体验，以及车共享服务，帮助品牌进行了数字钥匙系统用户端的建设，包含“钥匙流转、亲友共享、钥匙回收”等。",
          },
          content2: {
            title: "某日系豪华汽车品牌待客入厂服务",
            imgUrl: require("assets/images/0f916ba626eb3533258954d098d1eed.jpg"),
            msg:
              "基于数字钥匙的增值服务，车主通过数字钥匙授权的方式，代驾可无钥匙操作车辆进行待客返厂维保/充电等服务。",
          },
          content3: {
            title: "",
            imgUrl: require("assets/images/13.png"),
            msg: "",
          },
          content4: {
            title: "",
            imgUrl: require("assets/images/01.jpg"),
            msg: "",
          },
        },
        {
          content1: {
            title: "Volvo SCRM",
            imgUrl: require("assets/images/01-奔驰展厅.jpg"),
            msg:
              "以数据为依托，通过服务支持、营销支持、数据支持，覆盖客户户全生命周期引流转化，线上联动线下，聚合客户实现品牌共创。",
          },
          content2: {
            title: "",
            imgUrl: require("assets/images/0f916ba626eb3533258954d098d1eed.jpg"),
            msg: "",
          },
          content3: {
            title: "",
            imgUrl: require("assets/images/13.png"),
            msg: "",
          },
          content4: {
            title: "",
            imgUrl: require("assets/images/01.jpg"),
            msg: "",
          },
        },
        {
          content1: {
            title: "某日系豪华汽车品牌数据中台方案",
            imgUrl: require("assets/images/01-奔驰展厅.jpg"),
            msg:
              "各触点数据割裂，导致数据价值无法最大化、商业化，通过OneID的支持，结合数据治理、数据模型化、业务需求支持的建设，让数据价值最大化。",
          },
          content2: {
            title: "某德系豪华车品牌业务中台解决方案",
            imgUrl: require("assets/images/0f916ba626eb3533258954d098d1eed.jpg"),
            msg:
              "随着品牌业务的发展，各业务单独建设维护的成本日益升高，效率越来越低，通过对各业务线的梳理，共性业务的提取、整合、统一，为各业务快速引用、建设提供了有效支撑。",
          },
          content3: {
            title: "",
            imgUrl: require("assets/images/13.png"),
            msg: "",
          },
          content4: {
            title: "",
            imgUrl: require("assets/images/01.jpg"),
            msg: "",
          },
        },
        {
          content1: {
            title: "某日系汽车品牌智能客服系统",
            imgUrl: require("assets/images/01-奔驰展厅.jpg"),
            msg:
              "为了更好的接待客户，为品牌方进行了客服系统的升级建设，包含“机器人服务、语音识别、文字识别、关键信息提取、标签化、知识库建设”等支持。",
          },
          content2: {
            title: "",
            imgUrl: require("assets/images/0f916ba626eb3533258954d098d1eed.jpg"),
            msg: "",
          },
          content3: {
            title: "",
            imgUrl: require("assets/images/13.png"),
            msg: "",
          },
          content4: {
            title: "",
            imgUrl: require("assets/images/01.jpg"),
            msg: "",
          },
        },
      ],
      newsMsg: [],
    };
  },
  methods: {
    handleSPTrans() {
      this.serveProIndex = this.transState;
    },
    handleCaseTrans() {
      this.current = this.transState;
    },
    onStepChange(current) {
      this.caseState = !this.caseState;
      this.transState = current;
      setTimeout(() => {
        this.caseState = !this.caseState;
      }, 300);
    },
    jumpPage(routerName, index) {
      this.$router.push({
        name: routerName,
        params: {
          index,
        },
      });
    },
    _addClass(showIndex) {
      this.transState = showIndex;
      const ev = window.event || event;
      const path = ev.path || (ev.composedPath && ev.composedPath());
      if (ev.target.nodeName === "DIV") {
        return;
      }
      this.SPState = !this.SPState;
      this.items = document.getElementsByClassName("card");
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        item.classList.remove("cardActive");
      }
      path[3].classList.add("cardActive");
      setTimeout(() => {
        this.SPState = !this.SPState;
      }, 300);
    },
    goToNews(msg) {
      let routeData = this.$router.resolve({
        name: "News",
        query: {
          pageIndex: msg,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
  computed: {
    lang() {
      return this.$store.state.lang;
    },
  },
  watch: {
    immediate: true,
    lang(newLang, oldLang) {
      newsApi(newLang).then((res) => {
        this.newsMsg = res.data.rows;
        this.newsMsg.forEach((value, index, arr) => {
          this.newsMsg[index].createdAt = value.createdAt.slice(0, 10);
        });
        while (this.newsMsg.length <= 3) {
          this.newsMsg.push([]);
        }
      });
    },
  },
  mounted() {
    bannerApi()
      .then((res) => {
        this.bannerImgs = [];
        res.data.rows.forEach((value, index, arr) => {
          this.bannerImgs.push(value.thumbimg);
        });
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        console.log("error: ", error);
      });
    newsApi(this.$store.state.lang).then((res) => {
      this.newsMsg = res.data.rows;
      this.newsMsg.forEach((value, index, arr) => {
        this.newsMsg[index].createdAt = value.createdAt.slice(0, 10);
      });
      while (this.newsMsg.length <= 3) {
        this.newsMsg.push([]);
      }
    });
  },
};
</script>

<style lang="scss">
.isEn {
  .serveTitle {
    font-size: 13px;
  }
  .ant-steps-item-title {
    font-size: 12px;
  }
}
.ant-steps-dot {
  height: 100%;
  transform: translatey(5%);
  .ant-steps-item-title {
    width: 150px;
    height: 105px;
    margin: 0 0 0 10px;
    color: #fff !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-steps-icon-dot {
    background: #fff !important;
  }
}
.ant-steps-item-active {
  .ant-steps-item-title {
    font-weight: bold;
  }
  .ant-steps-icon-dot {
    width: 50px !important;
    height: 50px !important;
    transform: translate(-33%, -37%);
    background: url("http://cdn.valuetech.com.cn/images/%E9%A6%96%E9%A1%B5%E6%A1%88%E4%BE%8B%E5%AD%90%E9%A1%B9active.png")
      top left no-repeat !important;
  }
}
</style>
<style lang="scss" scoped>
@import "assets/scss/mixin.scss";
@import "assets/scss/base.scss";
@import "assets/scss/config.scss";
@import "assets/scss/global.scss";
.mask {
  width: 100%;
  height: 700px;
  margin-bottom: 500px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
img {
  border-radius: 5px;
}
.banner {
  object-fit: cover;
  border-radius: 0px;
}

.cardActive {
  margin-top: -50px !important;
  border: 1px solid rgba($color: red, $alpha: 0.7);
  h3 {
    color: #2a2a2a !important;
  }
}

.serverPro {
  margin-right: -170px;
}

.cardsBox {
  z-index: 2;

  .card {
    width: 176px;
    height: 197px;
    border-radius: 5px;
    margin: -25px 25px 25px 10px;
    text-align: center;
    h3 {
      color: #777777;
    }
  }
  .card:hover {
    margin-top: -50px;
    border: 1px solid rgba($color: red, $alpha: 0.7);
    h3 {
      color: #2a2a2a;
    }
  }
}

.casesShow {
  height: 758px;
  background-color: rgb(12, 106, 193);
  color: #fff;
}

.introduce {
  .card {
    height: 500px;
  }
  h3 {
    color: $colorA;
    text-align: center;
    margin-top: 15px;
    font-weight: 700;
  }
  p {
    color: $colorB;
  }
  .container {
    @include flex("center", "center");
    height: 450px;
    .item {
      width: 54%;
      // height: 100%;
      padding: 0px 25px;
    }
    .leftItem {
      width: 624px;
      @media screen and (min-width: 1600px) {
        width: 686px !important;
      }
      border-right: 2px dashed #adadad;
      img {
        height: 72%;
        margin: 25px 0;
      }
    }
    .rightItem {
      @include flex("center", "center");
      flex-wrap: wrap;
      div {
        width: 30%;
        height: 80%;
        margin: 5px 5px;
        img {
          position: relative;
          width: 100%;
          height: 60%;
          margin-top: 20px;
          object-fit: contain;
          box-shadow: 0px 1px 7px 0px rgba(181, 181, 181, 0.35);
          border-radius: 5px;
        }
      }
      p {
        height: 85px;
        padding: 0 13px;
      }
      a {
        font-size: 12px;
        display: block;
        width: 80px;
        height: 30px;
        border: 1px solid rgb(49, 100, 214);
        border-radius: 3px;
        background: #fff;
        text-align: center;
        color: rgb(49, 100, 214);
        text-decoration: none;
        padding: 5px;
      }
    }
  }
}

.newsCenter {
  margin: 0 50px;
  .container {
    height: 460px;
  }
  h3 {
    width: 262px;
    margin-top: -20px;
    text-align: left;
    font-weight: bold;
  }
  .newsTime {
    text-align: right;
    margin: -10px 15px 25px 0;
  }
  .leftItem {
    width: 613px !important;
    @media screen and (min-width: 1600px) {
      width: 536px !important;
    }
    p {
      color: #a7a7a7;
      // padding-right: 10px;
      overflow: hidden;
    }
    img {
      // width: 314.55px !important;
      // height: 159.15px !important;
      margin-top: 0 !important;
      object-fit: cover;
    }
    h3 {
      width: auto;
    }
  }
  td {
    img {
      height: 136px;
      object-fit: cover;
    }
  }

  .rightItem table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0px;
    table-layout: fixed;
  }

  .rightItem table tr td:nth-child(1) {
    width: 47%;
  }
  .rightItem table tr td:nth-child(2) {
    padding: 0 0 0 15px;
    h3 {
      margin-top: 0px;
    }
    p:nth-child(2) {
      padding: 0;
      height: 60px;
    }
    p:nth-child(3) {
      height: 20px;
      padding-top: 15px;
      text-align: right;
    }
  }
}

.brandImage {
  margin: 25px 75px;
}
</style>
